import { Box, Grid2 as Grid } from '@mui/material';
import InvestmentLayout from '../../components/layout/InvestmentLayout';
import PortfolioOverview from '../../components/widgets/PortfolioOverview';
import NewsImage from '../../images/news.png';

export default function Overview() {
  return (
    <>
      <InvestmentLayout>
        <Grid
          container
          rowSpacing={0}
          sx={{ width: '100%', maxWidth: '96em' }}
          justifyContent="flex-start"
        >
          <Grid size={{ sm : 12,  md : 8, lg : 6}}>
            <PortfolioOverview />
          </Grid>
          <Grid size={{ sm : 12,  md : 8, lg : 6}}>
            <Box className="board-image-box">
              <a
                href="https://coeli.se/aktuellt/"
                target="_blank"
                rel="noreferrer"
              >
                <Box
                  component="img"
                  src={NewsImage}
                  alt="News Image"
                  className="board-image"
                />
              </a>
            </Box>
          </Grid>
        </Grid>
      </InvestmentLayout>
    </>
  );
}
