import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Typography,
} from '@mui/material';
import { Grid2 as Grid } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { getActiveUser } from '../../redux/stateFuncs';
import { useApiClient } from '../../hooks/apiClient';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaDocumentDto } from '../../models/dto/faDocumentDto';
import DownloadIcon from '@mui/icons-material/FileDownload';
import { DocumentCategoryType } from '../../Enums';
import { FaContactDto } from '../../models/dto/faContactDto';
import { ExpandLess, ExpandMore, Widgets } from '@mui/icons-material';

interface IDocumentListProps {
  title: string;
  docs: FaDocumentDto[];
}

function DocumentList(props: IDocumentListProps) {
  const api = useApiClient();

  function handleDownloadDocument(
    docId: string,
    portfolioKey?: string,
    contactSSN?: string
  ) {
    const postData = {
      documentId: docId,
      portfolioKey: portfolioKey,
      contactSSN: contactSSN,
    };
    setDownloading(docId);
    api.post(`api/fasecurity/GetDocumentData`, postData).then(
      (response) => {
        // Decode Base64 string to binary
        const byteCharacters = atob(response.data.data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        // Create a Blob from binary data
        const blob = new Blob([byteArray], { type: 'application/pdf' }); // Adjust type based on file
        // Create a download link
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = response.data.fileName; // Set filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Clean up memory
        URL.revokeObjectURL(a.href);

        setDownloading(undefined);
      },
      (err) => {
        alert('Error');
      }
    );
  }

  const [open, setOpen] = React.useState(true);
  const [downloading, setDownloading] = useState<string | undefined>();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      {props.docs.length > 0 ? (
        <Box className="info-table-widet" mb={2}>
          <Grid container>
            <Grid
              size={{ xs: 12 }}
              paddingTop={1}
              borderBottom={'1px solid'}
              onClick={handleClick}
            >
              <Grid container>
                <Grid>
                  <Typography variant="h5">{props.title}</Typography>
                </Grid>
                <Grid
                  display={'flex'}
                  justifyContent={'flex-end'}
                  margin={'0 0 0 auto'}
                  pr={0.5}
                  pb={'2px'}
                >
                  {open ? <ExpandLess /> : <ExpandMore />}
                </Grid>
              </Grid>
            </Grid>
            <Grid container paddingTop={1}>
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                sx={{ width: '100%' }}
              >
                {props.docs.map((fd) => {
                  return (
                    <Grid
                      size={{ xs: 12 }}
                      className="rows"
                      paddingTop={1}
                      key={fd.identifier}
                    >
                      {downloading === fd.identifier ? (
                        <>
                          <CircularProgress size={20}></CircularProgress>
                        </>
                      ) : (
                        <>
                          <Button
                            className="document-name"
                            size="small"
                            sx={{ textTransform: 'none' }}
                            startIcon={
                              <DownloadIcon fontSize="small"></DownloadIcon>
                            }
                            onClick={() =>
                              handleDownloadDocument(
                                fd.identifier,
                                fd.portfolioKey,
                                fd.contactSocialSecurityNumber
                              )
                            }
                          >
                            {format(new Date(fd.lastModified), 'yyyy-MM-dd')}
                            {' - '}
                            {fd.portfolioKey != ''
                              ? fd.portfolioKey
                              : fd.contactName}
                            {' - '}
                            {fd.fileName}
                          </Button>
                        </>
                      )}
                    </Grid>
                  );
                })}
              </Collapse>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

export default function PortfolioDocuments() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);
  const [contactDocuments, setContactDocuments] = useState<FaDocumentDto[]>([]);

  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  const customers = useAppSelector((state) => state.customers);
  const portfolios = useAppSelector((state) => state.portfolios);

  var now = format(new Date(), 'yyyy-MM-dd');

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    var docs: FaDocumentDto[] = [];

    const fetchData = async () => {
      await Promise.all(
        users.map((user) => {
          return api
            .get<FaContactDto>(
              'api/FaSecurity/GetContactDocuments/' + user.socialSecurityNumber
            )
            .then((res) => {
              if (res.data.documents != null) {
                res.data.documents.forEach((element) => {
                  element.contactName = customers.find(
                    (f) => f.contactId == element.contactSocialSecurityNumber
                  )?.name;
                });
                docs = docs.concat(res.data.documents);
              }
            })
            .catch((err) => {
              console.error(err);
              setError('Error loading customer documents');
            });
        })
      );

      setContactDocuments(docs);
      setIsLoading(false);
    };

    fetchData();
  }, [users]);

  return (
    <Box className="portfolio-overview">
      {error ? (
        <>
          <Box display="flex" justifyContent="center" my={6}>
            <Typography color="error.main">{error}</Typography>
          </Box>
        </>
      ) : (
        <>
          {isLoading ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container>
              <Grid>
                {portfolios
                  .filter((p) => p.show === true)
                  .flatMap((p) => p.documents).length > 0 ? (
                  <>
                    <DocumentList
                      title={t('documentCategory.AnnualReports')}
                      docs={portfolios
                        .filter((p) => p.show === true)
                        .flatMap((p) =>
                          p.documents
                            .filter(
                              (d) =>
                                d.category == DocumentCategoryType.AnnualReport
                            )
                            .map((fd) => {
                              return fd;
                            })
                        )}
                    />
                    <DocumentList
                      title={t('documentCategory.Exante')}
                      docs={portfolios
                        .filter((p) => p.show === true)
                        .flatMap((p) =>
                          p.documents
                            .filter(
                              (d) => d.category == DocumentCategoryType.Exante
                            )
                            .map((fd) => {
                              return fd;
                            })
                        )}
                    />
                    <DocumentList
                      title={t('documentCategory.DeclarationDocuments')}
                      docs={portfolios
                        .filter((p) => p.show === true)
                        .flatMap((p) =>
                          p.documents
                            .filter(
                              (d) =>
                                d.category ==
                                DocumentCategoryType.DeclarationDocuments
                            )
                            .map((fd) => {
                              return fd;
                            })
                        )}
                    />
                    <DocumentList
                      title={t('documentCategory.Other')}
                      docs={portfolios
                        .filter((p) => p.show === true)
                        .flatMap((p) =>
                          p.documents
                            .filter(
                              (d) => d.category == DocumentCategoryType.Other
                            )
                            .map((fd) => {
                              return fd;
                            })
                        )}
                    />
                    <DocumentList
                      title={t('documentCategory.QuarterlyReports')}
                      docs={portfolios
                        .filter((p) => p.show === true)
                        .flatMap((p) =>
                          p.documents
                            .filter(
                              (d) =>
                                d.category ==
                                DocumentCategoryType.QuarterlyReport
                            )
                            .map((fd) => {
                              return fd;
                            })
                        )}
                    />
                  </>
                ) : (
                  <></>
                )}
                {/*  Add customers documents here  */}
                <DocumentList
                  title={t('documentCategory.SecurityInformation')}
                  docs={contactDocuments
                    .filter(
                      (d) =>
                        customers.find(
                          (u) => u.contactId == d.contactSocialSecurityNumber
                        )?.show == true
                    )
                    .filter(
                      (d) =>
                        d.category == DocumentCategoryType.SecurityInformation
                    )
                    .map((fd) => {
                      return fd;
                    })}
                />
                <DocumentList
                  title={t('documentCategory.Signed')}
                  docs={contactDocuments
                    .filter(
                      (d) =>
                        customers.find(
                          (u) => u.contactId == d.contactSocialSecurityNumber
                        )?.show == true
                    )
                    .filter((d) => d.category == DocumentCategoryType.Signed)
                    .map((fd) => {
                      return fd;
                    })}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Box>
  );
}
