import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { Grid2 as Grid } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { FaPortfolioOverviewDto } from '../../models/dto/faPortfolioOverviewDto';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getActiveUser } from '../../redux/stateFuncs';
import { useApiClient } from '../../hooks/apiClient';
import CurrencyField from '../formfields/CurrencyField';
import PercentageField from '../formfields/PercentageField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Edit as EditIcon } from '@mui/icons-material'
import { setPortfolios,updatePortfolioDisplayName } from '../../redux/slices/portfolios';

interface portfolioDevelopment {
  name: string;
  shortName: string;
  displayName: string;
  ownerId: string;
  value: number;
  change: number;
  id: string;
  agreementType: string;
  modelPortfolio: string;
}

interface portfolioRenameProps
{
  id : string,
  displayName : string,
  setName: (name : string, portfolioShortName : string) => void;
}

function RenameDialog(props : portfolioRenameProps)
{
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const api = useApiClient();
  const dispatch = useAppDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <Tooltip title="Rename">
      <IconButton onClick={handleClickOpen} color="primary">
        <EditIcon className="edit-icon"></EditIcon>                          
      </IconButton>
    </Tooltip>

     <Dialog
        fullWidth={true}      
        maxWidth="md"
        sx={{ '& .MuiDialog-paper': { width: '90%', maxHeight: 200 } }}
        scroll="paper"     
        open={open}
        onClose={handleClose}        
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const portfolioName = formJson.name;

            const postData = {
              id: props.id,
              name: portfolioName,              
            };
            
            api.post(`api/fasecurity/renamePortfolio`, postData).then(() =>
              {              
                // Set name in overview
                props.setName(postData.name, postData.id );
                // Send namechange to redux
                dispatch(updatePortfolioDisplayName({ shortName: postData.id, name: postData.name }));
              }
            )
            .catch((err) => {
              console.log(err);
              alert("Unable to rename portfolio!");              
            }
            ).finally(() => 
              {            
                handleClose();
              }
            );            
          },
        }}
      >
        <DialogTitle>{props.displayName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('changeName.Info')}
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            type="text"
            fullWidth
            variant="standard"    
            slotProps={{ htmlInput: { maxLength: 20 } }}
          />
        </DialogContent>
        <DialogActions>
          {loading ? <>
            <CircularProgress size={20}></CircularProgress>
           </> :
          <>
            <Button variant='contained' type="submit">{t('Confirm')}</Button>
            <Button variant='outlined' onClick={handleClose}>{t('Cancel')}</Button>          
          </>}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default function PortfolioOverview() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);
  const [portfolioOverview, setPortfolioOverviews] = useState<
    portfolioDevelopment[]
  >([]);

  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  const portfolios = useAppSelector((state) => state.portfolios);
  const customers = useAppSelector((state) => state.customers);
  var activeUser = getActiveUser(users);

  var now = format(new Date(), 'yyyy-MM-dd');
  var firstday = format(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd');

  const setName = (name : string, portfolioShortName : string) => {
    const newPortfolioOverviews = portfolioOverview.map((p) => {
      if (p.shortName === portfolioShortName) {
        p.displayName = name;
      }
      return p;
    });
    setPortfolioOverviews(newPortfolioOverviews);
  }

  useEffect(() => {
    setIsLoading(true);
    const postData = {
      socialSecurityNumber: activeUser?.socialSecurityNumber,
      fromDate: firstday,
      toDate: now,
    };
    api
      .post<FaPortfolioOverviewDto[]>(
        'api/FaSecurity/GetPortfolioOverview',
        postData
      )
      .then((res) => {
        portfolioOverview.splice(0);
        res.data.map((i) => {
          portfolioOverview.push({
            id: i.id,
            ownerId: i.ownerId,
            name: i.name,
            shortName: i.shortName,
            value: i.positionMarketValue + i.cashBalance,
            change: i.twr,
            agreementType: i.agreementType,
            displayName: i.displayName,
            modelPortfolio: i.modelPortfolio
          } as portfolioDevelopment);
          return '';
        });
        setIsLoading(false);
        setError(null);
      })
      .catch((err) => {
        setError('Error loading portfolio overview');
      });
  }, [activeUser]);

return  (
  <>
    
    <Box className="portfolio-overview">
      {error ? (
        <>
          <Box display="flex" justifyContent="center" my={6}>
            <Typography color="error.main">{error}</Typography>
          </Box>
        </>
      ) : (
        <>
          {isLoading ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid
              container
              rowSpacing={1}
              sx={{
                '--Grid-borderWidth': '2px',
                '& > div': {
                  borderBottom: 'var(--Grid-borderWidth) solid',
                },
              }}
            >
              <Grid size={{ xs: 7 }}>
                <Box display="flex" justifyContent="flex-start">
                  <Typography className="text" textAlign={'left'}>
                    {t('Portfolio')}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={{ xs: 5 }}>
                <Box display="flex" justifyContent="flex-end">
                  <Typography className="text" textAlign={'right'}>
                    {t('Marketvalue')}
                    <br />
                    {t('ProgressThisYear')}
                  </Typography>
                </Box>
              </Grid>
              {portfolioOverview
                .filter((po) =>
                  portfolios
                    .filter((p) => p.show === true)
                    .map((p) => p.id)
                    .includes(po.id)
                )
                .map((p) => {
                  return (
                    <React.Fragment key={p.shortName}>
                      <Grid size={{ xs: 7 }}>
                          <Box>
                            <Grid container>
                              <Grid>                                
                                <Typography className="text">
                                  {p.displayName.includes(p.shortName) ? 
                                    <>{p.displayName}</>
                                    :
                                    <>{p.displayName} ({p.shortName})</>
                                  } {' '}                                  
                                </Typography>
                              </Grid>
                              <Grid>
                                <RenameDialog id={p.shortName} displayName={p.displayName} setName={setName}></RenameDialog>
                              </Grid>
                            </Grid>
                            <Typography className="text">
                              {
                                customers.find((c) => c.contactId === p.ownerId)
                                  ?.name
                              }
                            </Typography>
                            <Typography>
                              {p.modelPortfolio}
                            </Typography>
                          </Box>
                      </Grid>
                      <Tooltip title={p.name}>
                        <Grid size={{ xs: 5 }} textAlign={'right'}>
                          <CurrencyField value={p.value} />
                          <PercentageField value={p.change} />
                        </Grid>
                      </Tooltip>
                    </React.Fragment>
                  );
                })}
              <Grid size={{ xs: 7 }}>
                <Tooltip title={t('Sum')}>
                  <Box>
                    <Typography className="boldtext">{t('Sum')}</Typography>
                    <Typography className="text"></Typography>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid size={{ xs: 5 }} textAlign={'right'}>
                {portfolioOverview.length > 0 ? (
                  <CurrencyField
                    className="boldtext"
                    value={portfolioOverview
                      .filter((po) =>
                        portfolios
                          .filter((p) => p.show === true)
                          .map((p) => p.id)
                          .includes(po.id)
                      )
                      .map((c) => c.value)
                      .reduce((sum, cur) => sum + cur, 0)}
                  />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Box>
    </>
  );
}
