import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FaPortfolioDto } from '../../models/portfolio';

const initialState: FaPortfolioDto[] = [];

export const portfoliosSlice = createSlice({
  name: 'portfolios',
  initialState,
  reducers: {
    setPortfolios: (state, action: PayloadAction<FaPortfolioDto[]>) => {
      state = [];
      return [...state, ...action.payload];
    },
    showPortfoliosForCustomer: (state, action: PayloadAction<string>) => {
      const portfolios = state.filter(
        (portfolio) => portfolio.ownerId === action.payload
      );
      portfolios.forEach((c) => (c.show = true));
    },
    hidePortfoliosForCustomer: (state, action: PayloadAction<string>) => {
      const portfolios = state.filter(
        (portfolio) => portfolio.ownerId === action.payload
      );
      portfolios.forEach((c) => (c.show = false));
    },
    showPortfolio: (state, action: PayloadAction<string>) => {
      const portfolio: FaPortfolioDto | undefined = state.find(
        (portfolio) => portfolio.id === action.payload
      );
      if (portfolio) {
        portfolio.show = true;
      }
    },
    hidePortfolio: (state, action: PayloadAction<string>) => {
      const portfolio: FaPortfolioDto | undefined = state.find(
        (portfolio) => portfolio.id === action.payload
      );
      if (portfolio) {
        portfolio.show = false;
      }
    },
    hideAllPortfolios: (state) => {
      state.forEach((c) => (c.show = false));
    },
    showAllPortfolios: (state) => {
      state.forEach((c) => (c.show = true));
    },
    showPortfolios: (state, action: PayloadAction<string[]>) => {
      state.forEach((c) => {
        if (action.payload.find((p) => p === c.id)) c.show = true;
        else c.show = false;
      });
    },
    clearPortfolios: (state) => {
      state = [];
    },
    updatePortfolioDisplayName: (state, action: PayloadAction<{ shortName: string; name: string }>) => {
      const portfolio: FaPortfolioDto | undefined = state.find(
        (portfolio) => portfolio.shortName === action.payload.shortName
      );
      if (portfolio) {
        portfolio.displayName = action.payload.name;
      }
    },
  },
});

export const {
  setPortfolios,
  showPortfolio,
  hidePortfolio,
  hideAllPortfolios,
  showPortfoliosForCustomer,
  hidePortfoliosForCustomer,
  showPortfolios,
  showAllPortfolios,
  clearPortfolios,
  updatePortfolioDisplayName
} = portfoliosSlice.actions;
export default portfoliosSlice.reducer;
